:root {
  --blue-01   : #80bdff;
  --black-01  : #0a0a0a;
  --black-02  : #003559;
  --gray-01   : #eee;
  --gray-02   : #80868b;
  --gray-03   : #6c757d;
  --gray-04   : #e6e6e6;
  --white-01  : #f6f6f6;
  --white-02  : #f4f4f4;
}

*:focus {
  outline: 0;
}

.btn.focus,
.btn:focus,
button:focus,
input:focus,
textarea:focus {
  box-shadow: none;
  outline: 0;
}

body {
  overflow-y: scroll;
}

.lift {
  transition: box-shadow .25s ease, transform .25s ease;

  &:hover {
    transform: translate3d(0, -5px, 0);
    box-shadow: 0 2rem 5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .05);
  }
}

.lds-ring {
  display: inline-block;
  height: 80px;
  position: relative;
  width: 80px;
}

.lds-ring div {
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 8px solid var(--blue);
  border-color: var(--blue) transparent transparent transparent;
  border-radius: 50%;
  height: 64px;
  margin: 8px;
  position: absolute;
  width: 64px;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
