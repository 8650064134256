$black    : #000;
$black-01 : #0a0a0a;
$black-02 : #161c2d;
$white    : #fff;

.Meme {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &--loading {
    align-items: center;
    justify-content: center;
  }

  &__github {
    transform: translateY(5px);

    > span:not(:first-child) {
      margin-left: .5rem;
    }
  }

  &__title {
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
  }

  &__updated {
    font-size: .95rem;
  }

  &__search {
    margin-bottom: 2.5rem;

    .form-control {
      padding-top: .25rem;

      &::-webkit-search-cancel-button {
        color: var(--blue-01);
        cursor: pointer;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &-query {
      font-style: italic;
      margin: 1rem 0 0;
      text-align: right;
    }
  }

  &__body {
    flex: 1 1 auto;
  }

  &__item {
    align-items: center;
    border: 1px solid rgba($black-01, .1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 1.85rem;
    padding: 1rem 1rem 2rem;
    position: relative;
    width: 100%;

    .tooltip {
      animation: fadeInUp .15s linear both;
      right: 0;
      top: calc(100% + 8px);

      .arrow {
        right: 10px;
      }
    }

    .tooltip-inner {
      display: block;
    }
  }

  &__img {
    margin-bottom: .5rem;
    max-width: 100%;
    transition: all .2s ease;

    &:hover {
      opacity: .85;
    }
  }

  &__sub {
    font-size: .9rem;
    margin-bottom: .5rem;
    word-break: break-all;
  }

  &__copy {
    align-items: center;
    background: var(--white);
    border-bottom-right-radius: 8px;
    border-left: 1px solid rgba($black-01, .1);
    border-top: 1px solid rgba($black-01, .1);
    border-top-left-radius: 8px;
    bottom: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    padding: 6px;
    position: absolute;
    right: 0;
    transition: all .25s ease;
    z-index: 1;

    &:hover {
      background: var(--gray-01);
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-size: 1.05rem;
    justify-content: flex-end;
    margin: 1.5rem 0;
  }

  &__history {
    left: 0;
    position: fixed;
    top: 0;
    z-index: 10;
  }

  &__dropdown {
    &.show > button.btn-secondary:not(:disabled):not(.disabled) {
      svg {
        fill: var(--blue);
      }
    }

    > button.btn-secondary:not(:disabled):not(.disabled) {
      background: var(--white);
      border-color: rgba($black, .15);
      padding-bottom: .65rem;

      &:focus,
      &:active {
        background: var(--white);
        border-color: rgba($black, .2);
        box-shadow: none;
      }

      &:hover {
        background: var(--white-01);
      }

      svg {
        fill: var(--gray-02);
      }
    }
  }

  &__dropdown-toggle[aria-expanded='true'] {

    &:before,
    &:after {
      border-style: solid;
      border-width: 10px 9px;
      content: '';
      left: 16px;
      position: absolute;
    }

    &:before {
      border-color: transparent transparent rgba($black, .15);
      bottom: -16px;
      z-index: 1000;
    }

    &:after {
      border-color: transparent transparent var(--white);
      bottom: -17px;
      z-index: 1001;
    }
  }

  &__dropdown-menu.dropdown-menu {
    border-radius: 6px;
    margin: 16px 0 0 -5px;
    max-height: 60vh;
    max-width: 22rem;
    min-width: 20rem;
    overflow: auto;
    padding: .8rem 1rem 1.5rem 1.2rem;

    .empty {
      align-items: center;
      color: var(--gray-03);
      display: flex;
      justify-content: center;
      margin-top: .6rem;

      svg {
        fill: var(--gray-03);
      }
    }

    .icons {
      .clear {
        cursor: pointer;
        line-height: 0;
        position: absolute;
        right: 16px;
        top: 18px;
        z-index: 1;

        &:hover svg {
          fill: var(--blue);
        }
      }

      .title {
        font-size: 1rem;
        margin-bottom: 1.2rem;
      }
    }

    .icon {
      align-items: center;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .thumb {
        border-radius: 50%;
        box-shadow: 0 0 0 .08rem rgba($black-02, .05);
        display: inline-block;
        flex-shrink: 0;
        height: 60px;
        margin-right: .8rem;
        padding: 10px;
        width: 60px;
      }

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }

      .value {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: .95rem;
        overflow: hidden;
        padding-right: 1rem;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .time {
          color: var(--gray-02);
          font-size: .8rem;
          font-weight: 300;
        }
      }

      .remove {
        cursor: pointer;
        font-size: 1.5rem;
        margin-left: auto;
        margin-top: -3px;
        user-select: none;

        &:hover {
          color: var(--blue);
        }
      }
    }
  }

  .form-control:focus,
  .btn:focus {
    outline: 0;
    box-shadow: none;
  }

  .form-control[readonly] {
    background-color: rgba(233, 236, 239, .35);

    &:focus {
      border-color: #007bff;
    }
  }
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
